const cookies = {
  get(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");
    const cookie = cookies.find((cookie) => cookie.startsWith(`${name}=`));

    if (cookie) {
      return cookie.split("=")[1];
    }

    return null;
  },

  set(name, value, options = {}) {
    let cookieString = `${name}=${value}`;

    if (options.expires) {
      const expires = new Date(options.expires).toUTCString();
      cookieString += `; expires=${expires}`;
    }

    if (options.path) {
      cookieString += `; path=${options.path}`;
    }

    if (options.domain) {
      cookieString += `; domain=${options.domain}`;
    }

    if (options.secure) {
      cookieString += "; secure";
    }

    document.cookie = cookieString;
  },

  remove(name) {
    this.set(name, "", { expires: new Date(0) });
  },
};

export default cookies;
