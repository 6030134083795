import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  #boundVisibilityChangeHandler;
  #boundRegisterServiceWorker;
  #boundExecuteReload;

  constructor() {
    super();
    this.#boundVisibilityChangeHandler =
      this.#visibilityChangeHandler.bind(this);
    this.#boundRegisterServiceWorker = this.#registerServiceWorker.bind(this);
    this.#boundExecuteReload = this.#executeReload.bind(this);
  }

  async connect() {
    console.log(`[ServiceWorker]`, "PWA detected. Registering service worker.");

    const worker =
      (await this.#serviceWorkerRegistration) ||
      (await this.#boundRegisterServiceWorker());

    if (worker) {
      console.log(`[ServiceWorker]`, "Service worker registered.");
      worker.update();
      window.dispatchEvent(new CustomEvent("service-worker-installed"));
    }

    window.addEventListener(
      "visibilitychange",
      this.#boundVisibilityChangeHandler,
    );
  }

  disconnect() {
    window.removeEventListener(
      "visibilitychange",
      this.#boundVisibilityChangeHandler,
    );
  }

  #visibilityChangeHandler() {
    if (document.visibilityState === "visible") {
      console.log(`[ServiceWorker]`, "Page is visible. Reloading.");
      this.#boundExecuteReload();
    }
  }

  get #serviceWorkerRegistration() {
    return navigator.serviceWorker.getRegistration(window.location.host);
  }

  async #registerServiceWorker() {
    return navigator.serviceWorker
      .register("/service-worker.js?v=2", {
        onUpdate: (registration) => {
          console.log(`[ServiceWorker]`, "Service worker updated.");
          const waitingServiceWorker = registration.waiting;
          if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event) => {
              if (event.target.state === "activated") {
                if (
                  window.confirm(
                    "There is a new version of the app ready. Please reload to update.",
                  )
                ) {
                  this.#boundExecuteReload();
                  window.location.reload();
                }
              }
            });
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
          }
        },
      })
      .then((registration) => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.addEventListener("statechange", () => {
          if (installingWorker.state == "installed") {
            console.log(`[ServiceWorker]`, "Service worker installed.");

            // Broadcast an event to the client that the service worker is installed
            window.dispatchEvent(new CustomEvent("service-worker-installed"));
          }
        });
      });
  }

  get #isPWA() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  #executeReload() {
    const entriesView = document.querySelector(".entries-view");
    if (entriesView) {
      const refreshSrc = entriesView.dataset.refreshSrc;
      entriesView.src = refreshSrc;
    }
  }
}
