import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Observe changes in the target element
    this.observer = new MutationObserver(() => {
      this.scrollToBottom();
    });

    const observerConfig = { childList: true };

    this.observer.observe(this.element, observerConfig);

    this.scrollToBottom();
  }

  disconnect() {
    this.observer.disconnect();
  }

  scrollToBottom() {
    if (this.pageIsTurboPreview()) {
      return;
    }

    const targetElement = this.element.lastElementChild;
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  pageIsTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview");
  }
}
