import { Controller } from "@hotwired/stimulus";
import cookies from "../libs/cookies";

export default class extends Controller {
  static values = {
    announcementId: String,
  };

  connect() {
    console.log("AnnouncementBarController connected");
  }

  close() {
    this.element.remove();

    // Set a cookie here that Rails can read to not show the announcement bar
    cookies.set("last_announcement_seen", this.announcementIdValue, {
      path: "/",
      secure: true,
    });
  }
}
