import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["description", "form", "input"];

  connect() {
    this.inputTarget.addEventListener("keydown", this.handleKeydown.bind(this));
    document.addEventListener("mousedown", this.handleOutsideClick.bind(this));
    this.isEditing = false;
  }

  disconnect() {
    this.inputTarget.removeEventListener(
      "keydown",
      this.handleKeydown.bind(this),
    );
    document.removeEventListener(
      "mousedown",
      this.handleOutsideClick.bind(this),
    );
  }

  edit(event) {
    if (this.isEditing) return;

    event.stopPropagation();
    this.descriptionTarget.classList.add("hidden");
    this.formTarget.classList.remove("hidden");
    this.element.classList.add("editing");
    this.element.classList.remove("cursor-move");
    this.inputTarget.focus();
    // Set cursor to the end of the input
    const length = this.inputTarget.value.length;
    this.inputTarget.setSelectionRange(length, length);
    this.isEditing = true;

    this.inputTarget.addEventListener(
      "mousedown",
      this.handleInputMousedown.bind(this),
    );
  }

  handleInputMousedown(event) {
    event.stopPropagation();
  }

  cancel() {
    this.formTarget.classList.add("hidden");
    this.descriptionTarget.classList.remove("hidden");
    this.element.classList.remove("editing");
    this.element.classList.add("cursor-move");
    this.inputTarget.value = this.descriptionTarget.textContent.trim();
    this.isEditing = false;

    // Remove mousedown event listener from the input
    this.inputTarget.removeEventListener(
      "mousedown",
      this.handleInputMousedown.bind(this),
    );
  }

  handleKeydown(event) {
    if (event.key === "Escape") {
      event.preventDefault();
      this.cancel();
    }
  }

  handleOutsideClick(event) {
    if (this.isEditing && !this.element.contains(event.target)) {
      this.cancel();
    }
  }

  update(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const updateUrl = form.dataset.taskListUrlValue;

    fetch(updateUrl, {
      method: "PATCH",
      body: formData,
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.element.classList.remove("editing");
        this.element.classList.add("cursor-move");
        this.isEditing = false;
        // Remove mousedown event listener from the input
        this.inputTarget.removeEventListener(
          "mousedown",
          this.handleInputMousedown.bind(this),
        );
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  complete(event) {
    event.preventDefault();
    const updateUrl = event.currentTarget.dataset.taskCompleteUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  uncomplete(event) {
    event.preventDefault();
    const updateUrl = event.currentTarget.dataset.taskCompleteUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  start(event) {
    event.preventDefault();
    console.log("start");
    const updateUrl = event.currentTarget.dataset.taskStartUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  park(event) {
    event.preventDefault();
    console.log("park");
    const updateUrl = event.currentTarget.dataset.taskParkUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }
}
