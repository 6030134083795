import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = { subscriptionsUrl: String };
  static targets = ["notAllowedNotice", "bell", "details"];
  static classes = ["attention"];

  async attemptToSubscribe() {
    if (this.#allowed) {
      console.log("[PushNotificationsController] attemptToSubscribe");
      const registration = await this.#serviceWorkerRegistration;

      switch (Notification.permission) {
        case "denied": {
          console.log(
            "[PushNotificationsController] attemptToSubscribe: denied",
          );
          this.#revealNotAllowedNotice();
          break;
        }
        case "granted": {
          console.log(
            "[PushNotificationsController] attemptToSubscribe: granted",
          );
          await this.#subscribe(registration);
          break;
        }
        case "default": {
          console.log(
            "[PushNotificationsController] attemptToSubscribe: default",
          );
          this.#requestPermissionAndSubscribe(registration);
        }
      }
    } else {
      this.#revealNotAllowedNotice();
    }
  }

  get #allowed() {
    return navigator.serviceWorker && window.Notification;
  }

  get #serviceWorkerRegistration() {
    return navigator.serviceWorker.getRegistration(window.location.host);
  }

  #revealNotAllowedNotice() {
    this.notAllowedNoticeTarget.showModal();
  }

  async #subscribe(registration) {
    await navigator.serviceWorker.ready;

    console.log("[PushNotificationsController] #subscribe");
    const sub = await registration.pushManager.getSubscription();

    if (!sub || sub == null || sub === undefined) {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.#vapidPublicKey,
      });

      console.log(
        "[PushNotificationsController] #subscribe: registering subscription",
      );

      await this.#syncPushSubscription(subscription);
    } else {
      await this.#syncPushSubscription(sub);
    }

    if (!registration.pushManager.getSubscription()) {
      console.log(
        "[PushNotificationsController] #subscribe: no subscription - so asking for permission and trying again",
      );
      this.#requestPermissionAndSubscribe(registration);
    }
  }

  async #syncPushSubscription(subscription) {
    const response = await post(this.subscriptionsUrlValue, {
      body: this.#extractJsonPayloadAsString(subscription),
      responseKind: "turbo-stream",
    });
    if (!response.ok) {
      console.log(
        "[PushNotificationsController] #syncPushSubscription: !response.ok",
      );
      subscription.unsubscribe();
    }

    console.log(
      "[PushNotificationsController] #syncPushSubscription: synced subscription",
    );
  }

  async #requestPermissionAndSubscribe(registration) {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log(
        "[PushNotificationsController] #requestPermissionAndSubscribe: granted",
      );
      this.#subscribe(registration);
    }
  }

  get #vapidPublicKey() {
    const encodedVapidPublicKey = document.querySelector(
      'meta[name="vapid-public-key"]',
    ).content;
    return this.#urlBase64ToUint8Array(encodedVapidPublicKey);
  }

  #extractJsonPayloadAsString(subscription) {
    const {
      endpoint,
      keys: { p256dh, auth },
    } = subscription.toJSON();
    return JSON.stringify({
      push_subscription: { endpoint, p256dh_key: p256dh, auth_key: auth },
    });
  }

  // VAPID public key comes encoded as base64 but service worker registration needs it as a Uint8Array
  #urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  #isHidden(item) {
    return item.offsetParent === null;
  }

  get #isPWA() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }
}
