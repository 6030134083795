import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { mobileNavOpen: Boolean };
  static targets = ["mobileNavEl"];

  initialize() {
    this.mobileNavOpen = false;
  }

  toggleMobileNav() {
    if (!this.mobileNavOpen) {
      this.mobileNavOpen = true;
      document.body.classList.add("overflow-hidden");
      this.mobileNavElTarget.classList.remove("hidden");
    } else {
      this.closeMobileNav();
    }
  }

  closeMobileNav() {
    this.mobileNavOpen = false;
    document.body.classList.remove("overflow-hidden");
    this.mobileNavElTarget.classList.add("hidden");
  }
}
